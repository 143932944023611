/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { styled, SupersetTheme, t, useTheme, GenericDataType } from '@superset-ui/core';
import { Form, FormItem, FormProps } from 'src/components/Form';
import Select from 'src/components/Select/Select';
import { Col, Row } from 'src/components';
import { InputNumber, TextArea } from 'src/components/Input';
import Button from 'src/components/Button';
import { Radio } from 'src/components/Radio';
import CheckboxControl from 'src/explore/components/controls/CheckboxControl';
import {
  COMPARATOR,
  ConditionalFormattingConfig,
  MULTIPLE_VALUE_COMPARATORS,
  CONDITIONTYPE,
  STRING_COMPARATORS,
} from './types';

const FullWidthInputNumber = styled(InputNumber)`
  width: 100%;
`;

const FullWidthTextArea = styled(TextArea)`
  width: 100%;
`;

const JustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledRadio = styled(Radio)`
  display: block;
  line-height: ${({ theme }) => theme.gridUnit * 7}px;
`;

const colorSchemeOptions = (theme: SupersetTheme) => [
  { value: theme.colors.success.light1, label: t('green') },
  { value: theme.colors.alert.light1, label: t('yellow') },
  { value: theme.colors.error.light1, label: t('red') },
  { value: theme.colors.warning.light1, label: t('orange') },
  { value: theme.colors.primary.light2, label: t('blue') },
  { value: theme.colors.grayscale.light2, label: t('gray') },
  { value: theme.colors.grayscale.light3, label: t('light gray') },
  { value: theme.colors.grayscale.light5, label: t('white') },
];

const colorSchemeFontOptions = (theme: SupersetTheme) => [
  { value: theme.colors.success.base, label: t('green') },
  { value: theme.colors.alert.base, label: t('yellow') },
  { value: theme.colors.error.base, label: t('red') },
  { value: theme.colors.warning.base, label: t('orange') },
  { value: theme.colors.primary.dark1, label: t('blue') },
  { value: theme.colors.grayscale.dark1, label: t('gray') },
  { value: theme.colors.grayscale.light5, label: t('white') },
];

const operatorOptions = [
  { value: COMPARATOR.NONE, label: 'None' },
  { value: COMPARATOR.IS_NULL, label: '= NULL' },
  { value: COMPARATOR.IS_NOT_NULL, label: '≠ NULL' },
  { value: COMPARATOR.GREATER_THAN, label: '>' },
  { value: COMPARATOR.LESS_THAN, label: '<' },
  { value: COMPARATOR.GREATER_OR_EQUAL, label: '≥' },
  { value: COMPARATOR.LESS_OR_EQUAL, label: '≤' },
  { value: COMPARATOR.EQUAL, label: '=' },
  { value: COMPARATOR.NOT_EQUAL, label: '≠' },
  { value: COMPARATOR.BETWEEN, label: '< x <' },
  { value: COMPARATOR.BETWEEN_OR_EQUAL, label: '≤ x ≤' },
  { value: COMPARATOR.BETWEEN_OR_LEFT_EQUAL, label: '≤ x <' },
  { value: COMPARATOR.BETWEEN_OR_RIGHT_EQUAL, label: '< x ≤' },
];

const conditionTypes = [
  { value: CONDITIONTYPE.VALUE, label: 'Value' },
  { value: CONDITIONTYPE.COLUMN, label: 'Column' },
]

const targetValueValidator =
  (
    compare: (targetValue: number, compareValue: number) => boolean,
    rejectMessage: string,
  ) =>
  (targetValue: number | string) =>
  (_: any, compareValue: number | string) => {
    if (
      !targetValue ||
      !compareValue ||
      compare(Number(targetValue), Number(compareValue))
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(rejectMessage));
  };

const targetValueLeftValidator = targetValueValidator(
  (target: number, val: number) => target > val,
  t('This value should be smaller than the right target value'),
);

const targetValueRightValidator = targetValueValidator(
  (target: number, val: number) => target < val,
  t('This value should be greater than the left target value'),
);

const isOperatorMultiValue = (operator?: COMPARATOR) =>
  operator && MULTIPLE_VALUE_COMPARATORS.includes(operator);

const isOperatorNone = (operator?: COMPARATOR) =>
  !operator || operator === COMPARATOR.NONE;

const rulesRequired = [{ required: true, message: t('Required') }];

type GetFieldValue = Pick<Required<FormProps>['form'], 'getFieldValue'>;
const rulesTargetValueLeft = [
  { required: true, message: t('Required') },
  ({ getFieldValue }: GetFieldValue) => ({
    validator: targetValueLeftValidator(getFieldValue('targetValueRight')),
  }),
];

const rulesTargetValueRight = [
  { required: true, message: t('Required') },
  ({ getFieldValue }: GetFieldValue) => ({
    validator: targetValueRightValidator(getFieldValue('targetValueLeft')),
  }),
];

const targetValueLeftDeps = ['targetValueRight'];
const targetValueRightDeps = ['targetValueLeft'];

const shouldFormItemUpdate = (
  prevValues: ConditionalFormattingConfig,
  currentValues: ConditionalFormattingConfig,
) =>
  isOperatorNone(prevValues.operator) !==
    isOperatorNone(currentValues.operator) ||
  isOperatorMultiValue(prevValues.operator) !==
    isOperatorMultiValue(currentValues.operator) ||
  prevValues.conditionType !== currentValues.conditionType ||
  prevValues.source!== currentValues.source ||
  prevValues.operator!== currentValues.operator;


const operatorField = (sourceType?: GenericDataType) => {
  let availOperators = operatorOptions;
  if (sourceType === GenericDataType.STRING) {
    availOperators = operatorOptions.filter(option => STRING_COMPARATORS.includes(option.value));
  }
  if (sourceType === GenericDataType.TEMPORAL) {
    availOperators = operatorOptions.filter(option => !MULTIPLE_VALUE_COMPARATORS.includes(option.value));
  }
  return (
    <FormItem
      name="operator"
      label={t('Operator')}
      rules={rulesRequired}
      initialValue={availOperators[0].value}
    >
      <Select ariaLabel={t('Operator')} options={availOperators} />
    </FormItem>
  );
}

let columnsExtra: { label: string; value: string; type: GenericDataType }[] = [];
let applyExtended: boolean;
// let comparableColumns: { label: string; value: string }[] = [];

const getColumType = (column : string) => 
  columnsExtra.find(col => col.value === column)?.type

const comparableColumns = (colType?: GenericDataType) => 
  columnsExtra.filter(col => col.type === colType)

const valueInput = (coltype?: GenericDataType) =>
  coltype === GenericDataType.TEMPORAL ? (
    <Radio.Group>
      <StyledRadio value="today">{t('Today')}</StyledRadio>
    </Radio.Group>
  ) : coltype === GenericDataType.STRING ? (
    <FullWidthTextArea />
  ) : (
    <FullWidthInputNumber />
  )

const sourceField = (defaultSource: string) =>
  <FormItem
    name="source"
    label={t('Source')}
    rules={rulesRequired}
    // for legacy conditions
    initialValue={defaultSource}
  >
    <Select ariaLabel={t('Select column')} disabled={!applyExtended} options={columnsExtra} />
  </FormItem>

const targetField = (conditionType: CONDITIONTYPE, coltype?: GenericDataType) =>
  conditionType === CONDITIONTYPE.VALUE ? (
    <FormItem
      name="targetValue"
      label={t('Target value')}
      rules={rulesRequired}
    >
      {valueInput(coltype)}
    </FormItem>
  ) : (
    <FormItem
      name="targetColumn"
      label={t('Target column')}
      rules={rulesRequired}
      // for legacy conditions
      initialValue={comparableColumns(coltype)[0].value}
    >
      <Select ariaLabel={t('Select column')} options={comparableColumns(coltype)} />
    </FormItem>
  );

const targetLeftField = (conditionType: CONDITIONTYPE, coltype?: GenericDataType) =>
  conditionType === CONDITIONTYPE.VALUE ? (
    <FormItem
      name="targetValueLeft"
      label={t('Left value')}
      rules={rulesTargetValueLeft}
      dependencies={targetValueLeftDeps}
      validateTrigger="onBlur"
      trigger="onBlur"
    >
      {valueInput(coltype)}
    </FormItem>
  ) : (
    <FormItem
      name="targetColumnLeft"
      label={t('Left column')}
      rules={rulesRequired}
      // for legacy conditions
      initialValue={comparableColumns(coltype)[0].value}
    >
      <Select ariaLabel={t('Select column')} options={comparableColumns(coltype)} />
    </FormItem>
  );

const targetRightField = (conditionType: CONDITIONTYPE, coltype?: GenericDataType) =>
  conditionType === CONDITIONTYPE.VALUE ? (
    <FormItem
      name="targetValueRight"
      label={t('Right value')}
      rules={rulesTargetValueRight}
      dependencies={targetValueRightDeps}
      validateTrigger="onBlur"
      trigger="onBlur"
    >
      {valueInput(coltype)}
    </FormItem>
  ) : (
    <FormItem
      name="targetColumnRight"
      label={t('Right column')}
      rules={rulesRequired}
      // for legacy conditions
      initialValue={comparableColumns(coltype)[0].value}
    >
      <Select ariaLabel={t('Select column')} options={comparableColumns(coltype)} />
    </FormItem>
  );

const renderGradientField = (column: string, source: string, conditionType: CONDITIONTYPE, coltype?: GenericDataType) =>
  conditionType === CONDITIONTYPE.VALUE && 
  // undefined for pivot tables
  (coltype === GenericDataType.NUMERIC || coltype === undefined) && 
  source === column ? (
    <Row gutter={12}>
      <Col span={9}>
        <FormItem
          name="gradient"
          label={t('Gradient')}
          >
          <CheckboxControl />
        </FormItem>
      </Col>
    </Row>
  ) : (
    <></>
  );

const renderOperatorFields = ({ getFieldValue }: GetFieldValue) =>
  isOperatorNone(getFieldValue('operator')) || getFieldValue('operator') === COMPARATOR.IS_NULL || getFieldValue('operator') === COMPARATOR.IS_NOT_NULL ? (
    <Row gutter={12}>
      <Col span={9}>
        {sourceField(getFieldValue('column'))}
      </Col>
      <Col span={6}>{operatorField(getColumType(getFieldValue('source')))}</Col>
    </Row>
  ) : isOperatorMultiValue(getFieldValue('operator')) ? (
    <>
      <Row gutter={12}>
        <Col span={9}>
          {sourceField(getFieldValue('column'))}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={9}>
          {targetLeftField(getFieldValue('conditionType'), getColumType(getFieldValue('source')))}
        </Col>
        <Col span={6}>{operatorField(getColumType(getFieldValue('source')))}</Col>
        <Col span={9}>
          {targetRightField(getFieldValue('conditionType'), getColumType(getFieldValue('source')))}
        </Col>
      </Row>
      {renderGradientField(getFieldValue('column'), getFieldValue('source'),getFieldValue('conditionType'), getColumType(getFieldValue('source')))}
    </>
  ) : (
    <>
      <Row gutter={12}>
        <Col span={9}>
          {sourceField(getFieldValue('column'))}
        </Col>
        <Col span={6}>{operatorField(getColumType(getFieldValue('source')))}</Col>
        <Col span={9}>
          {targetField(getFieldValue('conditionType'), getColumType(getFieldValue('source')))}
        </Col>
      </Row>
      {renderGradientField(getFieldValue('column'), getFieldValue('source'),getFieldValue('conditionType'), getColumType(getFieldValue('source')))}
    </>
  );

export const FormattingPopoverContent = ({
  config,
  onChange,
  columns = [],
  availTypes = [CONDITIONTYPE.VALUE],
}: {
  config?: ConditionalFormattingConfig;
  onChange: (config: ConditionalFormattingConfig) => void;
  columns: { label: string; value: string, type: GenericDataType }[];
  availTypes?: CONDITIONTYPE[];
}) => {
  const theme = useTheme();
  const colorScheme = colorSchemeOptions(theme);
  const colorSchemeFont = colorSchemeFontOptions(theme);
  columnsExtra = columns;
  if (columns[0]?.type !== undefined) {
    applyExtended = true;
  }
  return (
    <Form
      onFinish={onChange}
      initialValues={config}
      requiredMark="optional"
      layout="vertical"
    >
      <Row gutter={12}>
        {/* <Col span={9}> */}
        <Col span={15}>
          <FormItem
            name="column"
            label={t('Column')}
            rules={rulesRequired}
            initialValue={columns[0]?.value}
          >
            <Select ariaLabel={t('Select column')} options={columns} />
          </FormItem>
        </Col>
        {/* <Col span={6}>
          <FormItem
            name="colorScheme"
            label={t('Color scheme')}
            rules={rulesRequired}
            initialValue={colorScheme[0].value}
          >
            <Select ariaLabel={t('Color scheme')} options={colorScheme} allowNewOptions={true}/>
          </FormItem>
        </Col> */}
        <Col span={9}>
          <FormItem
            name="conditionType"
            label={t('Condition type')}
            rules={rulesRequired}
            initialValue={conditionTypes[0]?.value}
          >
            <Select ariaLabel={t('Select type')} options={conditionTypes} disabled={!applyExtended} />
          </FormItem>
        </Col>
      </Row>
      <FormItem noStyle shouldUpdate={shouldFormItemUpdate}>
        {renderOperatorFields}
      </FormItem>
      {/* <Divider /> */}
      <Row gutter={12}>
        <Col span={12}>
          <FormItem
            name="colorScheme"
            label={t('Background')}
            // rules={rulesRequired}
            // initialValue={colorScheme[0].value}
          >
            <Select ariaLabel={t('Background')} options={colorScheme} allowNewOptions allowClear/>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="colorSchemeFont"
            label={t('Font color')}
            // rules={rulesRequired}
            // initialValue={colorSchemeFont[0].value}
          >
            <Select ariaLabel={t('Font color')} options={colorSchemeFont} allowNewOptions allowClear/>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={7}>
          <FormItem
            name="fontBold"
            label={t('Bold')}
            >
            <CheckboxControl />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            name="fontItalic"
            label={t('Italic')}
            >
            <CheckboxControl />
          </FormItem>
        </Col>
        <Col span={9}>
          <FormItem
            name="fontUnderline"
            label={t('Underline')}
            >
            <CheckboxControl />
          </FormItem>
        </Col>
      </Row>
      <FormItem>
        <JustifyEnd>
          <Button htmlType="submit" buttonStyle="primary">
            {t('Apply')}
          </Button>
        </JustifyEnd>
      </FormItem>
    </Form>
  );
};
