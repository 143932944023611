/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { t, smartDateFormatter, NumberFormats } from '@superset-ui/core';

// D3 specific formatting config
export const D3_FORMAT_DOCS = t(
  'D3 format syntax: https://github.com/d3/d3-format',
);

// input choices & options
export const D3_FORMAT_OPTIONS: [string, string][] = [
  [NumberFormats.SMART_NUMBER, t('Adaptive formatting')],
  ['~g', t('Original value')],
  // Integers
  ['d', 'd (1234.56789 => 1235)'],
  [',d', ',d (1234.56789 => 1,235)'],
  // Floats without delimeters (exclude decimal if it is 0)
  ['.1~f', '.1~f (1234.56789 => 1234.6)'],
  ['.2~f', '.2~f (1234.56789 => 1234.57)'],
  ['.3~f', '.3~f (1234.00000 => 1234)'],
  ['.4~f', '.4~f (1234.56789 => 1234.5679)'],
  // Floats with delimeters (exclude decimal if it is 0)
  [',.1~f', ',.1~f (1234.56789 => 1,234.6)'],
  [',.2~f', ',.2~f (1234.56789 => 1,234.57)'],
  [',.3~f', ',.3~f (1234.00000 => 1,234)'],
  [',.4~f', ',.4~f (1234.56789 => 1,234.5679)'],
  // Percentage without delimeters (exclude decimal if it is 0)
  ['.1~%', '.1~% (12.3456789 => 1234.6%)'],
  ['.2~%', '.2~% (12.0000000 => 1234%)'],
  ['.3~%', '.3~% (12.3456789 => 1234.568%)'],
  ['.4~%', '.4~% (12.3456789 => 1234.5679%)'],
  // Percentage with delimeters (exclude decimal if it is 0)
  [',.1~%', ',.1~% (12.3456789 => 1,234.6%)'],
  [',.2~%', ',.2~% (12.0000000 => 1,2%)'],
  [',.3~%', ',.3~% (12.3456789 => 1,234.568%)'],
  [',.4~%', ',.4~% (12.3456789 => 1,234.5679%)'],
  // Floats without delimeters
  ['.1f', '.1f (1234.56789 => 1234.6)'],
  ['.2f', '.2f (1234.56789 => 1234.57)'],
  ['.3f', '.3f (1234.56789 => 1234.568)'],
  ['.4f', '.4f (1234.56789 => 1234.5679)'],
  // Floats with delimeters
  [',.1f', ',.1f (1234.56789 => 1,234.6)'],
  [',.2f', ',.2f (1234.56789 => 1,234.57)'],
  [',.3f', ',.3f (1234.56789 => 1,234.568)'],
  [',.4f', ',.4f (1234.56789 => 1,234.5679)'],
  // Percentage without delimeters
  ['.0%', '.0% (12.3456789 => 1235%)'],
  ['.1%', '.1% (12.3456789 => 1234.6%)'],
  ['.2%', '.2% (12.3456789 => 1234.57%)'],
  ['.3%', '.3% (12.3456789 => 1234.568%)'],
  ['.4%', '.4%d (12.3456789 => 1234.5679%)'],
  // Percentage with delimeters
  [',.0%', ',.0% (12.3456789 => 1,235%)'],
  [',.1%', ',.1% (12.3456789 => 1,234.6%)'],
  [',.2%', ',.2% (12.3456789 => 1,234.57%)'],
  [',.3%', ',.3% (12.3456789 => 1,234.568%)'],
  [',.4%', ',.4% (12.3456789 => 1,234.5679%)'],
  // Others
  ['.1s', '.1s (12345.432 => 10k)'],
  ['.3s', '.3s (12345.432 => 12.3k)'],
  ['+,', '+, (12345.432 => +12,345.432)'],
  ['$,.2f', '$,.2f (12345.432 => $12,345.43)'],
  ['DURATION', t('Duration in ms (66000 => 1m 6s)')],
  ['DURATION_SUB', t('Duration in ms (1.40008 => 1ms 400µs 80ns)')],
  // Extended
  ['>d','Hide int in excel'],
];

export const D3_TIME_FORMAT_DOCS = t(
  'D3 time format syntax: https://github.com/d3/d3-time-format',
);

export const D3_TIME_FORMAT_OPTIONS: [string, string][] = [
  [smartDateFormatter.id, t('Adaptive formatting')],
  ['%d.%m.%y', '%d.%m.%y | 14.01.19'],
  ['%d.%m.%Y', '%d.%m.%Y | 14.01.2019'],
  ['%d/%m/%Y', '%d/%m/%Y | 14/01/2019'],
  ['%m/%d/%Y', '%m/%d/%Y | 01/14/2019'],
  ['%Y-%m-%d', '%Y-%m-%d | 2019-01-14'],
  ['%d.%m.%Y %H:%M:%S', '%d.%m.%Y %H:%M:%S | 14.01.2019 01:32:10'],
  ['%Y-%m-%d %H:%M:%S', '%Y-%m-%d %H:%M:%S | 2019-01-14 01:32:10'],
  ['%d-%m-%Y %H:%M:%S', '%d-%m-%Y %H:%M:%S | 14-01-2019 01:32:10'],
  ['%H:%M:%S', '%H:%M:%S | 01:32:10'],
];

export const DEFAULT_NUMBER_FORMAT = D3_FORMAT_OPTIONS[0][0];
export const DEFAULT_TIME_FORMAT = D3_TIME_FORMAT_OPTIONS[0][0];
